
import Vue from 'vue'

export default Vue.extend({
  name: 'errorPage',
  data: () => ({
    errorCode: null,
    codeText: ''
  }),
  created (): void {
    const vm = this as any
    const code: string = vm.$route.params.code
    if (!code) return
    const hasReadableError = Number(code) >= 500 && Number(code) <= 526
    if (hasReadableError) this.errorCode = code
  },
  beforeRouteEnter (to, from, next) {
    const fromPage = localStorage.getItem('fromPage')
    if (fromPage !== null) {
      localStorage.removeItem('fromPage')
      next(fromPage)
    } else {
      next()
      localStorage.setItem('fromPage', to.params.from || from.fullPath)
    }
  },
  beforeRouteLeave (to, from, next) {
    localStorage.removeItem('fromPage')
    next()
  }
})
